import React from "react";
import styles from "./Features.module.css";
import image1 from "./assets/screenshot.png";
import image2 from "./assets/garbamail.svg";
import image3 from "./assets/salesforce.svg";
import image4 from "./assets/hubspot.svg";
import zoom from "./assets/zoom.svg";
import teams from "./assets/teams.svg"
import googlemeet from "./assets/googlemeet.svg"
import underlineGreen from "./assets/underline_green.svg"

const IncreasesSales = ({ targetRef }) => {
  return (
    <section id={"product"} ref={targetRef} className={styles.customSection}>
      <div className="container">
        <div className={styles.headerSection}>
          <div className={styles.salesProductivity}>
            <h3>Sales Productivity</h3>
          </div>
        </div>

        <div className={styles.sectionTitle}>
          <h2>Magic at the <span className={styles.styledText}> <img src={underlineGreen} alt="Party"/><span>click</span></span> of a button</h2>
          <div className={styles.subTitle}>
            <p>
              Taking notes is distracting. Updating the CRM is time-consuming.
              <br />
                Start engaging with your customers - not your keyboard.
            </p>

          </div>
        </div>

        <div className={styles.contentBox}>
          <div className={styles.imageWrapper}>
            <img src={image1} alt="Image 1" className={styles.boxImage} />
          </div>
          <div className={styles.textContent}>
            <h3>Human-like deal summaries</h3>
            <p>
              Garba captures and transcribes your meetings in real-time,
              delivering <strong>instant summaries</strong> with key outcomes, action items and
              next steps. Finally <strong>skip notetaking</strong> and stay fully engaged in the
              conversation.
            </p>
            <div className={styles.imageGroup}>
    <img src={teams} alt="Image 1" className={styles.image} />
    <img src={zoom} alt="Image 2" className={styles.image} />
    <img src={googlemeet} alt="Image 3" className={styles.image} />
  </div>
          </div>
        </div>

        <div className={styles.dualBoxContainer}>
          <div className={styles.leftBox}>
            <div className={styles.textContent}>
              <h3>Precisely tailored email drafts</h3>
              <p>
                Garba turns your meetings into <strong>highly personalized</strong> emails with
                precise next steps. Set the tone, purpose, and style to create
                the <strong>perfect</strong> message every time.
              </p>
            </div>
            <div className={styles.imageWrapper}>
              <img src={image2} alt="Image 2" className={styles.boxImage} />
            </div>
          </div>
          <div className={styles.rightBox}>
            <div className={styles.textContent}>
            <h3>CRM updates on autopilot</h3>

              <p>
                 <strong>Save hours</strong>, keep your manager happy,
                and maintain a single source of truth. It’s a <strong>win-win</strong> and then
                some!
              </p>
            </div>
            <div className={styles.stack}>
              <img src={image3} alt="Image 3" className={styles.boxImage2} />
              <img src={image4} alt="Image 4" className={styles.boxImage2} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IncreasesSales;
