function Privacy() {

    return (
        <div className={"container"}>
            <h1>Privacy Policy</h1>
            <p>
                Garba ("we," "our," or "us") is committed to protecting the privacy of users ("you," "your"). This
                Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use
                Garba, which provides services for recording, transcribing, and summarizing meetings. Please read this
                Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not use
                the app.
            </p>

            <h2>1. Information We Collect</h2>
            <p>
                Garba collects two types of information: personal information and non-personal information.
            </p>

            <h3>a. Personal Information:</h3>
            <p>
                When you use Garba, we may collect information that personally identifies you, such as:
            </p>
            <ul>
                <li><strong>User Account Information:</strong> Name, email address, and login credentials.</li>
                <li><strong>Meeting Data:</strong> Recordings of meetings, transcriptions, summaries, and any associated
                    metadata.
                </li>
                <li><strong>Device Information:</strong> Device identifiers, IP address, operating system, and browser
                    type.
                </li>
            </ul>

            <h3>b. Non-Personal Information:</h3>
            <p>
                Non-personal information includes anonymized data or information that does not directly identify you. We
                may collect:
            </p>
            <ul>
                <li><strong>Usage Data:</strong> App usage metrics, features accessed, session lengths, and performance
                    data.
                </li>
                <li><strong>Aggregated Meeting Insights:</strong> Statistical data about meetings that is anonymized.
                </li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
                <li>Provide, maintain, and improve the Garba app.</li>
                <li>Transcribe and summarize meeting content as requested.</li>
                <li>Communicate with you regarding account updates or technical support.</li>
                <li>Conduct analytics to improve user experience and app performance.</li>
                <li>Ensure security and prevent fraud.</li>
                <li>Comply with legal obligations.</li>
            </ul>

            <h2>3. How We Share Your Information</h2>
            <p>We do not sell or share your personal information with third parties for their marketing purposes.
                However, we may share your information in the following circumstances:</p>
            <ul>
                <li><strong>Service Providers:</strong> We may share data with third-party service providers that assist
                    in the app’s operation (e.g., cloud storage, transcription services).
                </li>
                <li><strong>Legal Compliance:</strong> We may disclose information if required by law, in response to
                    legal requests, or to protect the safety and rights of Garba and its users.
                </li>
                <li><strong>Business Transfers:</strong> In case of a merger, acquisition, or sale of assets, your
                    information may be transferred.
                </li>
            </ul>

            <h2>4. Data Security</h2>
            <p>
                We implement appropriate security measures to protect your personal information from unauthorized
                access, use, or disclosure. However, no data transmission over the internet or electronic storage is
                100% secure. While we strive to protect your information, we cannot guarantee its absolute security.
            </p>

            <h2>5. Data Retention</h2>
            <p>
                We retain your personal information only as long as necessary for the purposes outlined in this policy,
                or as required by law. Meeting recordings, transcriptions, and summaries are stored until deleted by you
                or your organization.
            </p>

            <h2>6. Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
                <li>Access, update, or delete your personal information.</li>
                <li>Opt out of marketing communications.</li>
                <li>Request restriction or object to the processing of your data.</li>
            </ul>
            <p>Please contact us at <a href="mailto:info@garba.ai">info@garba.ai</a> to exercise any of your rights.</p>

            <h2>7. Children’s Privacy</h2>
            <p>
                Garba is not intended for use by children under the age of 13. We do not knowingly collect personal
                information from children under 13. If we become aware that we have collected such information, we will
                take steps to delete it.
            </p>

            <h2>8. Changes to This Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. We will notify you of any material changes by
                posting the new policy on our website or app, and the "Effective Date" at the top will be updated.
            </p>

            <h2>9. Contact Us</h2>
            <p>
                If you have any questions or concerns regarding this Privacy Policy, please contact us at:<br/>
                <strong>Garba AB</strong><br/>
                <a href="mailto:info@garba.ai">info@garba.ai</a>
            </p>
        </div>
    );
}

export default Privacy;
