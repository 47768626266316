import React, {useRef} from "react";
import Navigation from "./components/Navigation";
import Hero from "./components/Hero";
import Testimonial from "./components/Testimonial";
import TeamFeatures from "./components/TeamFeatures";
import CTA from "./components/CTA";
import Footer from "./components/Footer";
import AIAssistant from "./components/AI-assistant";
import IncreasesSales from "./components/IncreaseSales";
import FontFaceObserver from "fontfaceobserver";

const regularFont = new FontFaceObserver("CabinetGrotesk", {weight: 400});
const boldFont = new FontFaceObserver("CabinetGrotesk", {weight: 700});

Promise.all([regularFont.load(), boldFont.load()])
    .then(() => {
        document.documentElement.classList.add("fonts-loaded");
    })
    .catch((error) => {
        console.error("Font failed to load:", error);
    });

function MainPage({ targetRef }) {

    return (
        <div className="App">
            <Hero/>
            <AIAssistant/>
            <IncreasesSales targetRef={targetRef}/>
            <Testimonial/>
            <TeamFeatures/>
            <CTA/>
        </div>
    );
}

export default MainPage;
