import React from "react";
import styles from "./Testimonial.module.css";
import testimonialImage from "./assets/kalle.png";

const Testimonial = () => {
  return (
    <section className={styles.testimonial}>
      <div className="container">
        <img
          src={testimonialImage}
          alt="Testimonial"
          className={styles.image}
        />
        <blockquote className={styles.quote}>
        “I highly recommend Garba to <strong>anyone in B2B sales</strong>. It simplifies the sales process for reps and provides me, as a sales manager, with <strong>invaluable insights</strong> into our performance and focus areas.”
        </blockquote>
        <p className={styles.author}>Kalle Mobeck, Sales Director, Twigeo</p>
      </div>
    </section>
  );
};

export default Testimonial;
