import React from "react";
import styles from "./Features.module.css";
import image1 from "./assets/screenshot2.png";
import image2 from "./assets/check.svg";
import image3 from "./assets/coach.svg";
import image4 from "./assets/hubspot.svg";
import zoom from "./assets/zoom.svg";
import teams from "./assets/teams.svg"
import googlemeet from "./assets/googlemeet.svg"
import underlinePurple from "./assets/underline_purple.svg"

const IncreasesSales = () => {
  return (
    <section className={styles.customSection}>
      <div className="container">
        <div className={styles.headerSection}>
          <div className={styles.salesCoaching}>
            <h3>Sales Coaching</h3>
          </div>
        </div>

        <div className={styles.sectionTitle}>
          <h2>Upgrade Your <span className={styles.styledText2}> <img src={underlinePurple} alt="Party"/><span>coaching</span></span> Game</h2>
          <div className={styles.subTitle}>
            <p>
            Unlock your team's full potential with Garba: gain insight into performance, unify your <br></br>strategy, and streamline onboarding for instant success.</p>
          </div>
        </div>

        <div className={styles.contentBox}>
          <div className={styles.imageWrapper}>
            <img src={image1} alt="Image 1" className={styles.boxImage} />
          </div>
          <div className={styles.textContent}>
            <h3>Level up team performance</h3>
            <p>
            With Garba, you get a <strong>full picture</strong> of your team's performance. Wondering if those sales trainings and strategies are paying off? Garba keeps your reps <strong>perfectly aligned</strong> with your game plan.
            </p>
           
          </div>
        </div>

        <div className={styles.dualBoxContainer}>
          <div className={styles.leftBox2}>
            <div className={styles.textContent}>
              <h3>Make everyone a winner</h3>
              <p>
              Whether you're just starting to implement a playbook or already using <strong>SPICE</strong> or <strong>MEDDPIC</strong>, Garba ensures that everyone can easily follow a <strong>winning</strong> strategy!
              </p>
            </div>
            {/*<div className={styles.imageWrapper}>*/}
            {/*  <img src={image2} width={50} alt="Image 2" className={styles.boxImage} />*/}
            {/*</div>*/}
          </div>
          <div className={styles.rightBox2}>
            <div className={styles.textContent}>
            <h3>Speed up onboarding</h3>

              <p>
              Build interactive knowledge hubs to capture <strong>top strategies</strong> and accelerate onboarding. Easily guide new reps through your playbook and help them <strong>succeed</strong> from day one!
              </p>
            </div>
            <div className={styles.imageWrapper}>
              <img src={image3} alt="Image 3" className={styles.boxImage21} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IncreasesSales;
