function Terms() {

    return (
        <div className="container">
            <h1>Terms of Use</h1>
            <p>
                Welcome to Garba! By accessing or using Garba ("we," "us," or "our")—a platform for recording,
                transcribing, and summarizing meetings—you agree to the following Terms of Use. Please read these terms
                carefully, as they govern your use of our services. If you do not agree with these Terms of Use, you
                should not use Garba.
            </p>

            <h2>1. Acceptance of Terms</h2>
            <p>
                By using Garba, you agree to be bound by these Terms of Use and any policies referenced herein. We may
                update these terms from time to time, and your continued use of Garba indicates your acceptance of the
                updated terms.
            </p>

            <h2>2. Description of Services</h2>
            <p>
                Garba provides tools for recording, transcribing, and summarizing meetings. The platform allows users
                to:
            </p>
            <ul>
                <li>Record meetings through various devices.</li>
                <li>Automatically generate transcriptions from recorded audio.</li>
                <li>Summarize meeting discussions for future reference.</li>
            </ul>

            <h2>3. User Responsibilities</h2>
            <p>
                When using Garba, you agree to:
            </p>
            <ul>
                <li>Provide accurate and current information during account registration.</li>
                <li>Maintain the security of your account login credentials.</li>
                <li>Use the app for lawful purposes and in compliance with applicable laws.</li>
                <li>Obtain appropriate consent from all meeting participants before recording, transcribing, or sharing
                    any meeting content.
                </li>
                <li>Respect the privacy and rights of other users and participants.</li>
            </ul>

            <h2>4. Prohibited Uses</h2>
            <p>
                You agree not to:
            </p>
            <ul>
                <li>Violate any applicable local, state, national, or international laws or regulations.</li>
                <li>Use Garba for any illegal, harmful, or fraudulent purposes.</li>
                <li>Transmit or distribute harmful content, including viruses, malware, or any other destructive
                    material.
                </li>
                <li>Interfere with the app’s functionality, security, or accessibility.</li>
                <li>Record or transcribe content without proper authorization from participants.</li>
            </ul>

            <h2>5. Intellectual Property</h2>
            <p>
                All content, features, and functionality on Garba, including but not limited to software, design, text,
                images, and logos, are the exclusive property of Garba or its licensors and are protected by copyright,
                trademark, and other intellectual property laws. You may not copy, modify, distribute, or create
                derivative works from any part of Garba without prior written permission from us.
            </p>

            <h2>6. User-Generated Content</h2>
            <p>
                By using Garba to record, transcribe, or summarize meetings, you retain ownership of any content you
                create ("User Content"). However, you grant Garba a non-exclusive, worldwide, royalty-free license to
                use, store, display, reproduce, and distribute your User Content to provide the services.
            </p>
            <p>
                You are solely responsible for ensuring that your User Content does not violate any third-party rights
                or applicable laws. Garba is not liable for any content that you upload, share, or record using our
                services.
            </p>

            <h2>7. Privacy and Data Protection</h2>
            <p>
                Our collection and use of personal information are governed by our Privacy Policy. By using Garba, you
                acknowledge and agree to the terms outlined in our Privacy Policy, including the collection, use, and
                sharing of your information.
            </p>

            <h2>8. Termination of Use</h2>
            <p>
                We reserve the right to suspend or terminate your access to Garba at any time, with or without notice,
                if you breach these Terms of Use or engage in any unlawful or harmful behavior. Upon termination, you
                must immediately cease all use of Garba, and we may delete your account and any associated data.
            </p>

            <h2>9. Limitation of Liability</h2>
            <p>
                To the fullest extent permitted by law, Garba, its affiliates, and its licensors shall not be liable for
                any indirect, incidental, special, or consequential damages arising from your use of or inability to use
                the app. This includes, but is not limited to, loss of data, loss of profits, or damages for business
                interruption, even if we have been advised of the possibility of such damages.
            </p>
            <p>
                Our total liability for any claim related to Garba shall not exceed the amount you have paid to us, if
                any, in the last six months.
            </p>

            <h2>10. Indemnification</h2>
            <p>
                You agree to indemnify, defend, and hold harmless Garba and its affiliates from and against any claims,
                liabilities, damages, losses, and expenses, including reasonable legal fees, arising out of or in
                connection with your use of the app, your violation of these Terms of Use, or your infringement of any
                rights of a third party.
            </p>

            <h2>11. Dispute Resolution</h2>
            <p>
                Any disputes arising out of or related to these Terms of Use or your use of Garba will be governed by
                and construed in accordance with the laws of Sweden. You agree to submit to the exclusive jurisdiction
                of the courts located in Sweden to resolve any legal matter arising from these terms.
            </p>

            <h2>12. Modifications to the Service</h2>
            <p>
                We reserve the right to modify or discontinue, temporarily or permanently, any aspect of Garba with or
                without notice. We are not liable for any modification, suspension, or discontinuation of the app.
            </p>

            <h2>13. Governing Law</h2>
            <p>
                These Terms of Use shall be governed by the laws of Sweden, without regard to its conflict of law
                provisions.
            </p>

            <h2>14. Contact Information</h2>
            <p>
                If you have any questions or concerns about these Terms of Use, please contact us at:
            </p>
            <p>
                Garba AB<br/>
                info@garba.ai
            </p>
        </div>

    );
}

export default Terms;
