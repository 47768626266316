import React from "react";
import styles from "./CTA.module.css";
import ctaImage from "./assets/cta.svg";
import ctaButtonImage from "./assets/cta_button.svg";

const CTA = () => {
    return (
        <section className={styles.cta}>
            <div className={styles.container}>
                <img src={ctaImage} alt="Try today" className={styles.image}/>
                <h2>Be the first to try Garba!</h2>
                <div className={styles.ctaButtonContainer}>
                    <a
                        href="https://8i07v9gsfzp.typeform.com/to/oLd0BUNy"
                        target={"_blank"}
                        className={styles.ctaButton}
                    >
                        Request early access
                    </a>
                </div>
            </div>
        </section>
    );
};

export default CTA;
