import React from "react";
import styles from "./Footer.module.css";
import logo from "./assets/garba-logo.svg";
import {Link} from "react-router-dom";



const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.logoSection}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </div>

        {/* Footer Content */}
        <div className={styles.footerContent}>
          <div className={styles.footerSection}>
            <h3>Product</h3>
            <ul>
              <li>
                <a href="#features">CRM sync</a>
              </li>
              <li>
                <a href="#pricing">Sales Coaching</a>
              </li>
              <li>
                <a href="#demo">Business Intelligence</a>
              </li>
            </ul>
          </div>
          
          {/* Garba Section */}
          <div className={styles.footerSection}>
            <h3>Garba</h3>
            <ul>
              <li>
                <a href="#om-oss">About us</a>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-of-use">Terms of Use</Link>
              </li>
              <li>
                <Link to="/support">Support</Link>
              </li>
            </ul>
          </div>

          {/* Resources Section */}
          <div className={styles.footerSection}>
            <h3>Resources</h3>
            <ul>
              <li>
                <a href="#blog">Blog</a>
              </li>
              <li>
                <a href="#case-studies">Pricing</a>
              </li>
              <li>
                <Link to="/zoom-guide">Zoom Guide</Link>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
