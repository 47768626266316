import React, { useEffect, useRef, useState } from "react";
import styles from "./AIAssistant.module.css";
import video from "./assets/hero_video.mp4";
import underlineGreen from "./assets/underline_green.svg";

const AIAssistant = () => {
    const videoRef = useRef(null);
    const sectionRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check if the user is on a mobile device
        const checkIfMobile = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (
                (window.innerWidth <= 768 && window.innerHeight <= 1024)
            ) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        checkIfMobile();

        const videoElement = videoRef.current;

        // Callback function to be executed when the section is in the viewport
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Start the video when the section is in view
                    videoElement.play();
                } else {
                    // Pause the video when the section is out of view
                    videoElement.pause();
                }
            });
        };

        // Create the Intersection Observer
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5, // Adjust this value as needed (0.5 means 50% of the section must be visible)
        });

        // Observe the section
        observer.observe(sectionRef.current);

        // Cleanup the observer when the component is unmounted
        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section className={styles.aiAssistant}>
            <div className="container">
                <div className={styles.sectionTitle}>
                    <h2>
                        From First{" "}
                        <span className={styles.styledText}>
              <img src={underlineGreen} alt="Party" />
              <span>Contact</span>
            </span>{" "}
                        to Close
                    </h2>
                    <div className={styles.subTitle}>
                        <p>
                            Improve your <b>conversion rates</b> and free up to 27 % of your
                            sales team's time with <br />
                            ​Garba - delivering <b>end-to-end assistance</b> throughout the
                            entire sales process.
                        </p>
                    </div>
                </div>
                <div ref={sectionRef} className={styles.content}>
                    <video
                        ref={videoRef}
                        src={video}
                        className={styles.centerImage}
                        loop
                        muted
                        playsInline
                        controls={isMobile} // Show controls if on mobile
                    />
                </div>
            </div>
        </section>
    );
};

export default AIAssistant;
