import React from "react";
import styles from "./Hero.module.css";
import partySvg from "./assets/cloud.svg"; // Make sure to import the SVG
import smallCloud from "./assets/cloud_small.svg"
import bigCloud from "./assets/cloud_large.svg"
import garba from "./assets/garba_hero.svg"
import podium from "./assets/podium.svg"
import sun from "./assets/sun.svg"
import {motion} from 'framer-motion';
import GarbaHeroSvg from "./GarbaHeroSvg";

const HeroWithFeatures = () => {
    return (
        <section className={styles.hero}>
            <div className={`container ${styles.heroContainer}`}>
                <div className={styles.content}>
                    <h1 className={styles.heroTitle}>
                        <span className={styles.partyText}> <img src={partySvg}
                                                                 alt="Party"/><span> Boost</span> </span>{" "}
                        Your Sales Game
                    </h1>
                    <div className={styles.features}>
                        <p>
                            Introducing Garba - the <strong>sales co-pilot</strong> that your
                            team will love! 😍
                        </p>
                    </div>
                </div>
            </div>
            <div  className={styles.heroPodiumWrapper}>
                <div className={styles.heroPodium}>
                    <div className={styles.floaters}>
                        <motion.div
                            className={styles.smallCloudLeft}
                            animate={{translateX: 50, rotate: 5}}
                            id="small-cloud"
                            initial={{translateX: -20, rotate: -5, originX: '50%', originY: '50%'}}
                            transition={{
                                duration: 10,
                                ease: 'easeInOut',
                                repeatType: 'reverse',
                                repeat: Infinity,
                                repeatDelay: 0.2,
                            }}>
                            <img src={smallCloud} alt="Small Cloud" className="small-cloud"/>
                        </motion.div>

                        <motion.div
                            className={styles.bigCloudLeft}
                            animate={{rotate: 5, translateX: -30,}}
                            id="big-cloud"
                            initial={{rotate: -5, translateX: 0, originX: '50%', originY: '50%'}}
                            transition={{
                                duration: 10.5,
                                ease: 'easeInOut',
                                repeatType: 'reverse',
                                repeat: Infinity,
                                repeatDelay: 1.5,
                                delay: 0.5,
                            }}>
                            <img src={bigCloud} alt="Big Cloud" className="big-cloud"/>
                        </motion.div>

                        <motion.div
                            className={styles.sun}
                            animate={{rotate: 10,}}
                            id="big-sun"
                            initial={{rotate: -10, originX: '50%', originY: '50%'}}
                            transition={{
                                duration: 10.5,
                                ease: 'easeInOut',
                                repeatType: 'reverse',
                                repeat: Infinity,
                                repeatDelay: 0,
                            }}>
                            <img src={sun} alt="sun" className="sun"/>
                        </motion.div>

                        <motion.div
                            className={styles.smallCloudRight}
                            animate={{translateX: 50, rotate: 0}}
                            id="small-cloud"
                            initial={{translateX: -20, rotate: 0, originX: '50%', originY: '50%'}}
                            transition={{
                                duration: 20.5,
                                ease: 'easeInOut',
                                repeatType: 'reverse',
                                repeat: Infinity,
                                repeatDelay: 0.2,
                            }}>
                            <img src={smallCloud} alt="Small Cloud" className="small-cloud"/>
                        </motion.div>


                    </div>
                    <div className="garba">
                        <GarbaHeroSvg></GarbaHeroSvg>
                    </div>
                    <img src={podium} alt="Podium" className="podium"/>
                </div>
            </div>
        </section>
    );
};

export default HeroWithFeatures;
