function Zoom() {

    return (
        <div className={"container"}>
            <h1>Garba App Guide for Zoom</h1>

            <h2>Dashboard Overview</h2>
            <p>
                Garba offers a user-friendly dashboard where you can manage all your meetings efficiently. The Dashboard
                serves
                as a central hub, allowing you to easily access past meetings for summaries, transcripts, and analysis,
                as well
                as upcoming meetings where Garba is scheduled to assist.
            </p>

            <h3>Key Features</h3>
            <ul>
                <li><strong>Past Meetings:</strong> Review summaries, transcripts, and key insights from previous
                    meetings
                    where Garba was used.
                </li>
                <li><strong>Upcoming Meetings:</strong> View your scheduled meetings from your integrated calendar,
                    where Garba
                    is set to join and automatically record, transcribe, and analyze.
                </li>
            </ul>

            <h2>Usage Guide: How to Use Garba</h2>
            <h3>1. Recording a Meeting with Garba</h3>
            <p>
                Garba allows you to capture meetings without needing to install anything directly in Zoom. Once
                connected,
                Garba operates automatically:
            </p>
            <h4>Scheduling Garba for a Meeting:</h4>
            <ul>
                <li>Make sure Garba is linked to your preferred calendar (Google Calendar, Outlook, etc.).</li>
                <li>When scheduling a meeting in your calendar, Garba will detect any upcoming Zoom meetings and will
                    automatically join based on your settings.
                </li>
                <li>Alternatively, you can visit the Upcoming Meetings section in Garba's dashboard and manually select
                    the
                    meetings you want Garba to attend.
                </li>
            </ul>

            <h3>2. Real-Time Meeting Assistance</h3>
            <p>
                Once Garba joins your Zoom meeting, it will begin recording and transcribing the conversation
                automatically.
                After the meeting is finished, you can watch the recording along with the transcription and summary
                under the
                Past Meetings part of the Garba App.
            </p>

            <h3>3. Post-Meeting Access: Summaries and Recordings</h3>
            <p>
                Once the meeting concludes, Garba processes and organizes the content for easy access:
            </p>
            <ul>
                <li><strong>Meeting Summary:</strong> Garba generates concise summaries of key points, tasks, and
                    decisions
                    from the meeting.
                </li>
                <li><strong>Full Transcription:</strong> Access the complete, searchable transcript of the meeting for
                    detailed
                    review.
                </li>
                <li><strong>Actionable Insights:</strong> Garba highlights key moments, recurring topics, and other
                    important
                    aspects using advanced AI.
                </li>
            </ul>

            <h2>Installation Guide</h2>
            <p>
                Garba is a SaaS platform, and no installation within Zoom is required. To start using Garba, follow
                these steps:
            </p>
            <h4>Sign Up for Garba:</h4>
            <p>Visit the Garba website and sign up for an account.</p>
            <h4>Connect Your Calendar:</h4>
            <ul>
                <li>To ensure Garba can automatically join your Zoom meetings, integrate your Google Calendar, Outlook
                    Calendar,
                    or any supported calendar service.
                </li>
                <li>Navigate to the Settings tab in the dashboard and connect your preferred calendar by granting the
                    necessary
                    permissions.
                </li>
            </ul>
            <h4>Authorize Zoom Integration:</h4>
            <p>
                Garba will require permission to join and record Zoom meetings. During the setup process, follow the
                prompts to
                authorize Garba’s access to your Zoom account.
            </p>

            <h2>Uninstallation Guide</h2>
            <p>
                If you no longer need Garba, you can easily disconnect it from your accounts:
            </p>
            <ul>
                <li><strong>Disconnect from Calendar:</strong> Head to Garba’s dashboard, navigate to Settings, and
                    disconnect
                    your calendar from the Calendar Integration section.
                </li>
                <li><strong>Revoke Zoom Permissions:</strong> Log into your Zoom account, go to App Permissions under
                    your Zoom
                    settings, and revoke Garba’s access.
                </li>
                <li><strong>Close Your Garba Account:</strong> To fully deactivate your Garba account, visit the Account
                    Settings page on the Garba website and select the option to close your account.
                </li>
            </ul>

            <h2>Troubleshooting</h2>
            <ul>
                <li><strong>Garba is not joining scheduled meetings:</strong> Ensure your calendar integration is active
                    and
                    that Garba has permission to access your Zoom account. Verify that Garba is set to automatically
                    join
                    scheduled meetings in your Settings.
                </li>
                <li><strong>Transcriptions are incomplete or missing:</strong> Ensure your internet connection was
                    stable
                    during the meeting. If the transcription appears incomplete, try refreshing the Past Meetings page.
                </li>
                <li><strong>Meeting summaries are delayed:</strong> Summaries may take a few minutes to generate
                    post-meeting.
                    If they are delayed, check back after a short time or refresh the page.
                </li>
            </ul>

            <h2>FAQ</h2>
            <ul>
                <li><strong>How do I set up Garba?</strong> Sign up on the Garba website, connect your calendar, and
                    authorize
                    Zoom access to allow Garba to join and record your meetings automatically.
                </li>
                <li><strong>How do I uninstall Garba?</strong> Disconnect Garba from your calendar and revoke Zoom
                    permissions
                    via the settings in both your Garba account and your Zoom account.
                </li>
                <li><strong>How do I get Garba to join my meetings?</strong> When scheduling a Zoom meeting, Garba will
                    automatically join based on your integrated calendar, or you can manually choose meetings from the
                    Upcoming
                    Meetings section.
                </li>
                <li><strong>How do I access recordings and transcripts?</strong> After a meeting, visit the Past
                    Meetings
                    section on Garba’s dashboard to access recordings, transcripts, and summaries.
                </li>
                <li><strong>Can I customize my meeting summaries?</strong> Yes, Garba allows you to adjust settings for
                    more
                    detailed or concise summaries, and you can highlight specific topics or sections of interest.
                </li>
            </ul>

            <p>
                Garba streamlines your meeting workflows without any Zoom app installation, making it easier to manage
                recordings, transcriptions, and summaries for all your important calls.
            </p>
        </div>
    );
}

export default Zoom;
