function Support() {

    return (
        <div className={"container"} style={{minHeight: "40vh", marginTop:"4rem"}}>
            <h1>Garba support</h1>
            <h2>We're here to help!</h2>
            <p>
                In case you need any support, please reach out to <a href={"mailto:support@garba.ai"}>support@garba.ai</a> for assistance.
                <br/>
                Our support team is working 9-5 CET monday to friday and you will hear from us within 24 hours.
            </p>
        </div>
    );
}

export default Support;
