import React from "react";
import {motion} from 'framer-motion';


const GarbaHeroSvg = () => {
    return (
        <svg style={{overflow: "visible"}} width="100%" height="100%" viewBox="0 0 289 237" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="garba_price">
                <motion.g id="right_arm"
                          animate={{rotate: -5}}
                          initial={{rotate: 5, originX: '50%', originY: '50%'}}
                          transition={{
                              duration: 2,
                              ease: 'easeInOut',
                              repeatType: 'reverse',
                              repeat: Infinity,
                              repeatDelay: 0.2,
                          }}>
                    <path id="Vector_12"
                          d="M194.617 110.202L253.761 81.3455C253.761 81.3455 266.263 75.6002 260.528 63.7192C254.752 51.8281 242.881 57.9738 242.881 57.9738L185.388 85.3793"
                          fill="#40EEC0"/>
                    <g id="Vector_13">
                        <path
                            d="M191.974 117.96L255.313 86.6304C255.313 86.6304 267.814 80.8851 262.039 69.0341C256.264 57.1431 244.423 63.2888 244.423 63.2888L190.683 88.9426"
                            fill="#40EEC0"/>
                        <path
                            d="M191.974 117.96L255.313 86.6304C255.313 86.6304 267.814 80.8851 262.039 69.0341C256.264 57.1431 244.423 63.2888 244.423 63.2888L190.683 88.9426"
                            stroke="#121926" stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <path id="Vector_14"
                          d="M272.008 68.2433C271.318 59.4952 263.621 52.9891 254.923 53.7098C246.184 54.4004 239.678 62.0576 240.399 70.8157L272.008 68.2433Z"
                          fill="#FFC074"/>
                    <path id="Vector_15"
                          d="M219.95 6.73621C221.341 23.9422 236.415 36.7441 253.601 35.3227C270.787 33.9315 283.589 18.8475 282.168 1.68152L219.95 6.73621Z"
                          fill="#FFC074"/>
                    <path id="Vector_16"
                          d="M259.267 53.3495L250.659 55.992L248.917 34.3018L257.655 33.6112L259.267 53.3896V53.3495Z"
                          fill="#FFC074"/>
                    <path id="Vector_17"
                          d="M220.34 11.4607L209.03 12.3815C209.03 12.3815 211.572 27.4656 228.658 27.0452"
                          fill="#FFC074"/>
                    <path id="Vector_18"
                          d="M265.973 36.0134L267.684 36.1535L266.723 35.0325L267.184 34.4419H266.423L266.824 32.7003L265.833 33.2909"
                          stroke="#121926" stroke-width="0.0800743" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_19"
                          d="M235.555 37.0444L234.073 37.8952L234.433 36.4739L233.773 36.1136L234.433 35.7532L233.312 34.402L234.464 34.5021"
                          stroke="#121926" stroke-width="0.0800743" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_20"
                          d="M266.363 68.0832C265.672 59.3651 257.985 52.829 249.277 53.5497C240.539 54.2403 234.033 61.9375 234.754 70.6556L266.353 68.0832H266.363Z"
                          stroke="#121926" stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                    <g id="Clip path group">
                        <mask id="mask0_55_2" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="212" y="0"
                              width="68" height="37">
                            <g id="a">
                                <path id="Vector_21" d="M212.303 0H279.035V36.8642H212.303V0Z" fill="white"/>
                            </g>
                        </mask>
                        <g mask="url(#mask0_55_2)">
                            <g id="Group">
                                <path id="Vector_22"
                                      d="M214.305 6.60608C215.696 23.802 230.77 36.6139 247.956 35.1926C265.142 33.8013 277.944 18.7173 276.523 1.51135L214.305 6.56604V6.60608Z"
                                      stroke="#121926" stroke-width="2.00186" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </g>
                        </g>
                    </g>
                    <path id="Vector_23"
                          d="M253.661 53.1893L244.923 53.91L243.472 36.0835L252.21 35.3928L253.661 53.2194V53.1893Z"
                          stroke="#121926" stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_24"
                          d="M276.623 5.34493L287.973 4.42407C287.973 4.42407 287.873 19.7383 270.947 22.0504"
                          stroke="#121926" stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_25"
                          d="M214.705 11.3206L203.395 12.2514C203.395 12.2514 205.937 27.3354 223.023 26.915"
                          stroke="#121926" stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                </motion.g>
                <path id="Vector"
                      d="M141.607 160.229V224.308C141.607 224.308 125.372 224.508 125.372 235.969H166.611V160.229"
                      fill="#40EEC0"/>
                <path id="Vector_2"
                      d="M87.1768 154.744V223.487C87.1768 223.487 70.9417 223.688 70.9417 235.999H112.18V154.784"
                      fill="#40EEC0"/>
                <path id="Vector_3"
                      d="M169.543 182.109L87.9275 184.261C68.8598 184.751 52.9951 169.697 52.4646 150.62L50.3826 71.5163C49.8922 52.4286 64.9361 36.5539 83.9938 36.0534L165.61 33.9014C184.677 33.411 200.542 48.4649 201.073 67.5427L203.144 146.646C203.635 165.724 188.601 181.609 169.533 182.139V182.099L169.543 182.109Z"
                      fill="#FFFD63"/>
                <path id="Vector_4"
                      d="M135.101 179.477L135.171 225.83C135.171 225.83 118.936 226 118.936 235.999H160.175L160.104 180.698"
                      stroke="#121926" stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_5"
                      d="M81.2013 180.568L81.4015 225.83C81.4015 225.83 65.1764 226 65.1764 235.999H106.415L106.184 179.176"
                      stroke="#121926" stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_6"
                      d="M44.317 134.805L35.709 142.162L52.4045 172.64C52.4045 172.64 59.5611 184.982 47.59 191.558C35.6189 198.124 29.283 185.873 29.283 185.873L12.1972 155.374C12.1972 155.374 1.73746 137.077 8.66389 130.251C22.2565 116.819 43.7665 99.1221 43.7665 99.1221"
                      fill="#94CCD4"/>
                <g id="Vector_7">
                    <path
                        d="M43.7865 127.839L30.6243 140.25L47.2798 168.707C47.2798 168.707 54.4364 181.018 42.4653 187.624C30.4942 194.19 24.1583 181.909 24.1583 181.909L5.79124 153.452C5.79124 153.452 -3.27718 136.487 3.60921 129.65C17.2018 116.218 42.205 94.4276 42.205 94.4276"
                        fill="#40EEC0"/>
                    <path
                        d="M43.7865 127.839L30.6243 140.25L47.2798 168.707C47.2798 168.707 54.4364 181.018 42.4653 187.624C30.4942 194.19 24.1583 181.909 24.1583 181.909L5.79124 153.452C5.79124 153.452 -3.27718 136.487 3.60921 129.65C17.2018 116.218 42.205 94.4276 42.205 94.4276"
                        stroke="#121926" stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <path id="Vector_8"
                      d="M163.898 177.094L75.3258 179.437C59.2309 179.867 45.8685 167.195 45.4381 151.08L43.0558 61.1168C42.6255 45.0318 55.3272 31.6294 71.3922 31.199L159.964 28.8568C176.059 28.4264 189.422 41.1382 189.852 57.2131L192.224 147.187C192.655 163.302 179.953 176.664 163.888 177.104L163.898 177.094Z"
                      stroke="#121926" stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_9" d="M88.8483 64.8802C88.8483 64.8802 97.096 71.9468 105.744 64.4198" stroke="#121926"
                      stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_10" d="M139.916 63.519C139.916 63.519 148.163 70.5856 156.811 63.0586" stroke="#121926"
                      stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_11"
                      d="M111.419 75.0396C111.419 75.0396 110.198 87.1909 124.972 86.7906C132.429 86.5904 136.192 82.8269 135.952 74.379"
                      stroke="#121926" stroke-width="2.00186" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_55_2">
                    <rect width="289" height="237" fill="white"/>
                </clipPath>
            </defs>
        </svg>


    );
};

export default GarbaHeroSvg;
